<template>
  <my-header></my-header>
  <div class="container">
    <div class="top">
      <div class="top_left">
        <div class="top_left_title"> {{topInfo.title}} </div>
        <div class="top_left_text"> {{topInfo.text}} </div>
      </div>
      <div class="top_right">
        <img class="top_right_img" :src="topInfo.img_1">
      </div>
    </div>
    <div class="flow">
      <div class="flow_title">
        <div class="flow_title_content">建设内容</div>
        <div class="flow_title_text">{{flowInfo.title_1}}{{flowInfo.title_2}}</div>
      </div>
      <div class="flow_box">
        <div class="flow_item">
          <div class="flow_item_box" v-for="(item, index) in flowInfo.list" :key="index" :class="index > 4 ? 'margin144': ''">
            <img class="flow_item_img" :src="item.img">
            <div class="flow_item_radius" :class="index == 0 ? 'flow_item_radius_1': '' "></div>
            <div class="flow_item_title"> {{item.title}} </div>
            <div class="flow_item_text"> {{item.text}} </div>
          </div>
          <img class="flow_line" :src="flowInfo.imgLine">
        </div>
      </div>
    </div>
    <!--项目成果-->
    <div class="achievement">
      <div class="achievement_case">
        <div class="headLine"> {{achievementInfo.caseHeadLine}} </div>
        <div class="title"> {{achievementInfo.caseTitle}} </div>
        <img class="QRcode" :src="achievementInfo.QRcode">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "case_detail",
    data(){
      return{
        topInfo:{
          title: '海南省第二人民医院',
          text: '　　海南省第二人民医院（五指山市人民医院）始建于1952年7月，是一所集医疗、科研、教学、急救、康复为一体的二级甲等综合医院。\n' +
            '为了让患者获得更好的就医服务体验，海南省第二人民医院携手玉螺互联网医疗共建互联网医疗服务平台，提供互联网便民服务。通过平台就可以轻松完成建档、挂号、缴费、查询等手续，精简线下就医流程，大大节省患者时间精力，让患者就医更便捷。',
          img_1: 'https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/case_2_1.png',
        },
        flowInfo:{
          headLine: '连接患者和服务，覆盖诊前、中、后全流程',
          list:[
            {
              text: '在线建档',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_sljd.png',
            },
            {
              text: '就诊卡管理',
              img: 'https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/case_2_4.png',
            },
            {
              text: '智能导诊',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_zndz.png',
            },
            {
              text: '预约挂号',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_ghjc.png',
            },
            {
              text: '核酸检测',
              img: 'https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/case_2_3.png',
            },
            {
              text: '住院预缴',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_zjzf.png',
            },
            {
              text: '历次就诊',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_cflz.png',
            },
            {
              text: '检查报告',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_bgcx.png',
            },
            {
              text: '门诊缴费',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_mzjf.png',
            },

          ],
          imgLine: 'https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/case_2_5.png'
        },
        operationInfo:{
          title: '提供「院内+院外」增值运营服务',
          list: [
            {
              title:  '健康管理',
              text: '为慢病患者全健康周期服务管理提供一站式解决方案。',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_detail_rmyy_jkgl.png'
            },
            {
              title:  '互联网+护理',
              text: '为失能、慢病、出院患者提供安全、专业、便捷的延续性护理服务，将护理服务延伸至社区和家庭。',
              imgList: [
                {imgSrc: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_detail_rmyy_hlwjhl_1.png'},
                {imgSrc: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_detail_rmyy_hlwjhl_2.png'}
              ]

            },
          ]
        },
        achievementInfo:{
          title: '项目成果',
          caseHeadLine: '查看案例',
          caseTitle: '使用微信的「扫一扫」功能，即刻体验',
          QRcode: 'https://nontax-cdn.yuluoo.com/yuluooOfficialWeb/case_2_2.png',
          list: [
            {
              icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_detail_rmyy_xmcg_2.png',
              title: '活跃用户达到',
              num: '102万+',
              value: '（十万）'
            },
            {
              icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_detail_rmyy_xmcg_3.png',
              title: '医院门诊量年增长',
              num: '10%↑',
            },
            {
              icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_detail_rmyy_xmcg_4.png',
              title: '缴费率达到',
              num: '80%',
            },
          ]
        }
      }
    },
    mounted(){
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
</script>

<style lang="stylus" scoped>
  .container{
    display flex
    flex-flow column
    font-family Alibaba PuHuiTi 2.0
    font-weight normal
    background #F5F5F7
    box-sizing border-box
    padding 0 140px
    .top{
      display flex
      align-items center
      justify-content space-between
      margin-top 60px
      .top_left{
        display flex
        flex-flow column
        width 674px
        .top_left_title{
          font-size 56px
          line-height 67px
          color #0069D9
        }
        .top_left_text{
          font-size 20px
          line-height 34px
          color #6C757D
          margin-top 50px
        }
      }
      .top_right{
        display flex
        margin-right 126px
        .top_right_img{
          width 392px
          height 800px
        }
        .top_right_img:last-child{
          margin 80px 0 0 80px
        }
      }
    }
    .flow{
      display flex
      flex-flow column
      /*padding 120px 0 150px 0*/
      margin-top 220px
      .flow_title{
        display flex
        flex-flow column
        font-size 56px
        line-height 67px
        color #23272B
        .flow_title_content{
          text-align center
          font-weight bold
          font-size 56px
          color #343A40
        }
        .flow_title_text{
          font-weight bold
          font-size 32px
          color #6C757D
          margin-top 27px
        }
      }
      .flow_box{
        background #ffffff
        border-radius 24px
        margin-top 117px
        .flow_item{
          display flex
          flex-wrap wrap
          justify-content flex-end
          box-sizing border-box
          padding 80px 80px 80px 0
          position relative
          .margin144{
            margin-top 206px
          }
          .flow_item_box{
            display flex
            flex-flow column
            align-items center
            text-align center
            width 20%
            .flow_item_img{
              width 80px
              height 80px
            }
            .flow_item_radius{
              width 10px
              height 10px
              background #ffffff
              border-radius 50%
              border 5px solid #007BFF
              margin 30px 0 20px 0
              z-index 666
            }
            .flow_item_radius_1{
              background #007BFF
              z-index 666
            }
            .flow_item_title{
              font-size 28px
              line-height 40px
              color #343A40
              width 166px
            }
            .flow_item_text{
              font-size 20px
              line-height 40px
              color #343A40
              width 200px
            }
          }

          .flow_line{
            height 400px
            position absolute
            bottom 148px
            left 160px
          }
        }
      }
    }
    .achievement{
      display flex
      flex-flow column
      margin-top 250px
      .title{
        font-size 56px
        line-height 67px
        color #23272B
      }
      .achievement_box{
        display flex
        justify-content space-between
        margin-top 50px
        .achievement_box_item{
          background #fff
          display flex
          flex-flow column
          justify-content center
          align-items center
          border-radius 24px
          width 536px
          height 489px
          /*margin-left 16px*/
          .item_icon{
            width 140px
            height 140px
          }
          .item_title{
            font-size 20px
            line-height 28px
            color #6C757D
            margin-top 37px
          }
          .item_num{
            font-size 44px
            line-height 62px
            color #343A40
            font-weight bold
          }
          .item_value{
            font-size 20px
            line-height 28px
            color #6C757D
          }
        }
        .textClass>.item_num{
          /*font-size 72px*/
          /*line-height 100px*/
        }
      }

      .achievement_case{
        background #ffffff
        display flex
        flex-flow column
        justify-content center
        align-items center
        border-radius 24px
        box-sizing border-box
        padding 80px 0 112px 0
        margin-top 16px
        .headLine{
          font-size 72px
          line-height 100px
          color #343A40
        }
        .title{
          font-size 20px
          line-height 28px
          color #6C757D
        }
        .QRcode{
          width 400px
          height 400px
          margin-top 80px
        }
      }
    }
  }
</style>
